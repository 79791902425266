/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { notFound } from 'next/navigation';

// import { dashboardConfig } from '@/config/dashboard';
// import { getCurrentUser } from '@/lib/session';
import Link from 'next/link';
import { signOut } from 'next-auth/react';
import { MainNav } from '../main-nav';
import { DashboardNav } from '../nav';
import { dashboardConfig } from '../../config/dashboard';
import ProfileNav from '../profile-nav';
import { MobileNav } from '../mobile-nav';
import { Icons } from '../Icons';
import { MainNavItem } from '../../models';
import { Button } from '../ui';
// import { UserAccountNav } from '@/components/user-account-nav';

interface DashboardLayoutProps {
  children?: React.ReactNode;
}

export default function DashboardLayout({ children }: DashboardLayoutProps) {
  const [showMobileMenu, setShowMobileMenu] = React.useState<boolean>(false);
  // const user = await getCurrentUser();

  // if (!user) {
  //   return notFound();
  // }

  return (
    <div className=" flex justify-start items-start max-h-screen flex-row border-red-300 overflow-hidden">
      <div className="  gap-12 flex w-fit h-full">
        {/* Sidebar */}
        <aside className="hidden px-4  py-10 border-r border-r-gray-300 bg-white w-full min-w-[220px]  h-fit min-h-screen flex-col md:flex justify-between">
          <div>
            <Link
              href="/app"
              className="hidden items-center mb-4 space-x-2 md:flex"
            >
              <img
                src="/media/logos/horizontal.png"
                alt="Logo"
                className="h-12 w-28 md:h-12 md:w-28"
              />
            </Link>
            <DashboardNav items={dashboardConfig.sidebarNav} />
          </div>
          <div className="flex flex-col gap-2">
            <DashboardNav items={dashboardConfig.helpSettings} />
            <Button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                signOut().then(() => {
                  // clean localstorage user
                  localStorage.removeItem('currentClientUser');
                  window.location.href = '/';
                });
              }}
            >
              Cerrar sesión
            </Button>
          </div>
        </aside>
      </div>
      {/* Topbar */}
      <div className="w-full  border-green-500  flex flex-col h-screen md:max-h-screen overflow-hidden">
        {/* Profile Nav */}
        <nav className="hidden md:flex border-b border-b-neutral-200">
          <ProfileNav />
        </nav>
        <div className=" md:hidden flex h-[64px] bg-white gap-6= md:gap-10">
          <button
            className="flex w-full border-red-400 justify-between px-10 items-center space-x-2 md:hidden"
            onClick={() => setShowMobileMenu(!showMobileMenu)}
            type="button"
          >
            <img
              src="/media/logos/horizontal.png"
              alt="Logo"
              className="h-12 w-28 md:h-12 md:w-28"
            />
            {showMobileMenu ? <Icons.close /> : <Icons.logo />}
          </button>
        </div>
        {showMobileMenu && (
          <MobileNav
            items={dashboardConfig.sidebarNav}
            setShowMobileMenu={setShowMobileMenu}
          />
        )}
        <main className="flex p-8  flex-1 w-full h-full flex-col overflow-auto">
          {children}
        </main>
      </div>
    </div>
  );
}
