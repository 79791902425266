'use client';

import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useState } from 'react';
import { cn } from '../lib/utils';
import { Icons } from './Icons';
import { SidebarNavItem } from '../models';
import SuggestionDialog from './suggestions/SuggestionDialog';

interface DashboardNavProps {
  items: SidebarNavItem[];
}

export function DashboardNav({ items }: DashboardNavProps) {
  const path = usePathname();
  const [openDialog, setOpenDialog] = useState(false);

  if (!items?.length) {
    return null;
  }

  return (
    <nav className="grid items-start gap-2">
      {items.map((item, index) => {
        const Icon = Icons[item.icon || 'arrowRight'];
        return item.title === 'Sugerencias' ? (
          <button
            key={index}
            type="button"
            onClick={() => setOpenDialog(true)}
            className={cn(
              'group flex items-center rounded-md px-3 py-2 text-sm font-medium text-slate-800 hover:bg-slate-100',
              path === item.href ? 'bg-slate-200' : 'transparent',
              item.disabled && 'cursor-not-allowed opacity-80'
            )}
          >
            <Icon className="mr-2 h-4 w-4" />
            <span>{item.title}</span>
          </button>
        ) : (
          item.href && (
            <Link key={index} href={item?.disabled ? '/' : item.href}>
              <span
                className={cn(
                  'group flex items-center rounded-md px-3 py-2 text-sm font-medium text-slate-800 hover:bg-slate-100',
                  path === item.href ? 'bg-slate-200' : 'transparent',
                  item.disabled && 'cursor-not-allowed opacity-80'
                )}
              >
                <Icon className="mr-2 h-4 w-4" />
                <span>{item.title}</span>
              </span>
            </Link>
          )
        );
      })}
      <SuggestionDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      />
    </nav>
  );
}
