import {
  Address,
  DniTypeEnum,
  Permission,
  Session,
  UserTypeEnum,
  User,
} from '../../models';

export const ACTIONS = {
  SET_EMAIL: 'SET_EMAIL',
  SET_PASSWORD: 'SET_PASSWORD',
  SET_FIRST_NAME: 'SET_FIRST_NAME',
  SET_LAST_NAME: 'SET_LAST_NAME',
  SET_LOCALE: 'SET_LOCALE',
  SET_PERMISSION: 'SET_PERMISSION',
  SET_USER_TYPE: 'SET_USER_TYPE',
  SET_EMAIL_VERIFY: 'SET_EMAIL_VERIFY',
  SET_RESET_TOKEN_VALIDITY: 'SET_RESET_TOKEN_VALIDITY',
  SET_RESET_TOKEN: 'SET_RESET_TOKEN',
  SET_DNI: 'SET_DNI',
  SET_DNI_TYPE: 'SET_DNI_TYPE',
  SET_COMMISSION: 'SET_COMMISSION',
  SET_SESSIONS: 'SET_SESSIONS',
  SET_STUDENT_ID: 'SET_STUDENT_ID',
  SET_GENDER: 'SET_GENDER',
  SET_BIRTH_DATE: 'SET_BIRTH_DATE',
  SET_PHONE: 'SET_PHONE',
  SET_ADDRESSES: 'SET_ADDRESSES',
  SET_PROFILE_PICTURE: 'SET_PROFILE_PICTURE',
} as const;

export type UserActions =
  | { type: 'SET_EMAIL'; payload: string }
  | { type: 'SET_PASSWORD'; payload: string }
  | { type: 'SET_FIRST_NAME'; payload: string }
  | { type: 'SET_LAST_NAME'; payload: string }
  | { type: 'SET_LOCALE'; payload: string }
  | { type: 'SET_PERMISSION'; payload: Permission[] }
  | { type: 'SET_USER_TYPE'; payload: UserTypeEnum }
  | { type: 'SET_EMAIL_VERIFY'; payload: boolean }
  | { type: 'SET_RESET_TOKEN_VALIDITY'; payload: Date }
  | { type: 'SET_RESET_TOKEN'; payload: string }
  | { type: 'SET_DNI'; payload: string }
  | { type: 'SET_DNI_TYPE'; payload: DniTypeEnum }
  | { type: 'SET_COMMISSION'; payload: number }
  | { type: 'SET_SESSIONS'; payload: Session[] }
  | { type: 'SET_GENDER'; payload: string }
  | { type: 'SET_BIRTH_DATE'; payload: Date }
  | { type: 'SET_PHONE'; payload: string }
  | { type: 'SET_PROFILE_PICTURE'; payload: string }
  | { type: 'SET_ADDRESSES'; payload: Address[] };

export const initialUser: User = {
  slug: '',
  email: '',
  password: '',
  firstName: '',
  lastName: '',
  locale: '',
  permission: [],
  userType: 'client',
  emailVerify: false,
  resetTokenValidity: undefined,
  resetToken: '',
  dni: '',
  dniType: 'V',
  commission: 0,
  sessions: [],
  gender: '',
  birthDate: new Date(),
  phone: '',
  profilePicture: '',
  addresses: [
    {
      state: 'caracas',
      city: '',
      firstLine: '',
      secondLine: '',
      postalCode: '',
    },
  ],
};

export function userReducer(state: User, action: UserActions): User {
  switch (action.type) {
    case ACTIONS.SET_EMAIL:
      return { ...state, email: action.payload };
    case ACTIONS.SET_PASSWORD:
      return { ...state, password: action.payload };
    case ACTIONS.SET_FIRST_NAME:
      return { ...state, firstName: action.payload };
    case ACTIONS.SET_LAST_NAME:
      return { ...state, lastName: action.payload };
    case ACTIONS.SET_LOCALE:
      return { ...state, locale: action.payload };
    case ACTIONS.SET_PERMISSION:
      return { ...state, permission: action.payload };
    case ACTIONS.SET_USER_TYPE:
      return { ...state, userType: action.payload };
    case ACTIONS.SET_EMAIL_VERIFY:
      return { ...state, emailVerify: action.payload };
    case ACTIONS.SET_RESET_TOKEN_VALIDITY:
      return { ...state, resetTokenValidity: action.payload };
    case ACTIONS.SET_RESET_TOKEN:
      return { ...state, resetToken: action.payload };
    case ACTIONS.SET_DNI:
      return { ...state, dni: action.payload };
    case ACTIONS.SET_DNI_TYPE:
      return { ...state, dniType: action.payload };
    case ACTIONS.SET_COMMISSION:
      return { ...state, commission: action.payload };
    case ACTIONS.SET_SESSIONS:
      return { ...state, sessions: action.payload };
    case ACTIONS.SET_GENDER:
      return { ...state, gender: action.payload };
    case ACTIONS.SET_BIRTH_DATE:
      return { ...state, birthDate: action.payload };
    case ACTIONS.SET_PHONE:
      return { ...state, phone: action.payload };
    case ACTIONS.SET_PROFILE_PICTURE:
      return { ...state, profilePicture: action.payload };
    case ACTIONS.SET_ADDRESSES:
      return { ...state, addresses: action.payload };
    default:
      return state;
  }
}
