import React from 'react';
import Breadcumb from './Breadcumb';
import { useUser } from '../hooks';

function ProfileNav() {
  const routes = [
    {
      name: 'Home',
      path: '/',
      icon: '',
      selected: false,
    },
    {
      name: 'Profile',
      path: '/profile',
      icon: '',
      selected: true,
    },
  ];

  const [user, setUser] = useUser();

  return (
    <div className="w-full bg-white h-fit min-h-[120px] px-2 py-4 border-yellow-200 flex flex-col gap-2 ">
      {/* User details */}
      <div className="flex gap-4 items-center">
        {/* Profile pic */}
        <div className="w-fit h-fit rounded-full">
          <img
            src="/avatar-placeholder.png"
            alt="profile"
            className="h-20 w-20 object-fill  rounded-full"
          />
        </div>
        {/* User details */}
        <div className="flex flex-col">
          <p className="text-2xl font-bold">
            {user?.firstName ?? 'John'} {user?.lastName ?? 'Doe'}
          </p>
          <p className="text-sm text-gray-500">
            <span className="font-semibold">
              {user?.email ?? 'email@gmail.com'}
            </span>
          </p>
        </div>
      </div>
      {/* Breadcrumb */}
      {/* <div className="p-4 ">
        <Breadcumb routes={routes} />
      </div> */}
    </div>
  );
}

export default ProfileNav;
