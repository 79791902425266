import { DashboardConfig } from '../models';

export const dashboardConfig: DashboardConfig = {
  mainNav: [
    {
      title: 'Documentation',
      href: '/docs',
    },
    {
      title: 'Support',
      href: '/support',
      disabled: true,
    },
  ],
  sidebarNav: [
    {
      title: 'Hacer un envío',
      href: '/app/shipment',
      icon: 'check',
    },
    {
      title: 'Envíos',
      href: '/app/orders',
      icon: 'post',
    },
    {
      title: 'Fletes',
      href: '/app/freights',
      icon: 'page',
    },
    {
      title: 'Cotizaciones',
      href: '/app/quotations',
      icon: 'page',
    },
    {
      title: 'Mis pagos',
      href: '/app/payments',
      icon: 'billing',
    },
    {
      title: 'Calculadora',
      href: '/app/calculator',
      icon: 'add',
    },
    {
      title: 'Sugerencias',
      href: '/app/suggestions',
      icon: 'help',
    },
    // {
    //   title: 'Cerrar sesión',
    //   href: '/logout',
    //   icon: 'logout',
    // },
  ],
  helpSettings: [
    {
      title: 'Nuestra dirección',
      href: '/app/address',
      icon: 'post',
    },
    {
      title: 'Configuración',
      href: '/app/settings',
      icon: 'settings',
    },
  ],
};
